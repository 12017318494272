import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import "../../css/patient_chat.css";

const ChatApp = () => {
  const [messages, setMessages] = useState([]);

  const navigate = useNavigate();

//***************Token********************* */
//check the token not tok then redirect the login(login time set token)
     useEffect(() => {
      // Get the token from localStorage 
      if (!localStorage.getItem('authToken')) {
        // If no token, redirect to Page 1 or login page
        navigate('../');
      }
    }, []);

  const addMessage = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  return (
    <>
    
    <div className="chat-app">
    <h3 className="d-flex justify-content-center">Chat</h3>

      <MessageList messages={messages}/>
      <MessageInput addMessage={addMessage} />
      
    </div>
    </>
  );
};

export default ChatApp;
