import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import "../../css/patient_chat.css";

const MessageList = ({ messages }) => {

   //patient get msg mate display the opsit 
   const[patient, setPatient]  = useState([]); //patient mate

//****************************Only patient & Doctor data show**************************************** */    
//patient login karse tyare patient baju thee patient and doctor na data dekhase aja api ma

const loadPatient = async () => {
  try {

      // login ma thee set kare id
      const Patient_Id_1 = sessionStorage.getItem('patientId');
      
      if(Patient_Id_1){
       console.log('Patient_Id_1',Patient_Id_1)

        // http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/patient/p_chat_select.php
        //./../api/patient/p_chat_select.php
        const result_P = await axios.get('./../api/patient/p_chat_select.php',{
          params:{pat_id:Patient_Id_1}
        });

      
        setPatient(result_P.data.patientMsg);
      } else {
        setPatient([]); // Set as empty array if no messages found
    }

  } catch (error) {
      // console.error('Error fetching patient data:', error);
      setPatient([]); // Set user to empty array in case of error
  }
}

  useEffect(() => {
      loadPatient();
  }, []);

         
  return (

<>
 {/* *********************Only show Right side Patient & Doctor data show*************************   */}
    
    <div className="message-list">
 
    {/* Display Patient messages if available */}
    {patient.length > 0 ? (
    patient.map((res, index) => (
    <div 
      key={index} 
      className={`${res.sender === 'doctor' ? 'message_left' : 'message_right'}`}
    >
      {/* Display sender's name */}
      <h5>{res.sender === 'doctor' ? 'Dr.' : 'Patient'}</h5>

      {/* Display the message */}
      {res.message}
    </div>
  ))
) : (
  <p>No messages available</p>
)}
   

  {/* *********************Only Patient Message sent*************************   */}
  
    {messages.length > 0 ? (
      messages.map((msg, index) => (
        <div key={index} className="message">

          {/*patient message sent */}
          {msg}
        </div>
      ))
    ) : (
      <div className="no-messages">No messages yet</div> // Fallback for no patient messages
    )}
  </div>
  </>
);
}
export default MessageList;
