import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import "../../css/doctor_chat.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const DoctorMsgInput = ({ addMessage }) => {
    const [storedId, setStoredId] = useState('');  //doctor id get mate
    const [id_patient, setId_patient] = useState('');  //patient id get mate
    const [inputValue, setInputValue] = useState(''); // Store message input

    // Retrieve patient ID from session storage
    useEffect(() => {
        const sessionId = sessionStorage.getItem('doctorId'); 
        if (sessionId) {
          setStoredId(sessionId); // Set stored ID from session
        }
      }, []);
      

      // ahiya id patient_details (chat icon button)page ma thee ave che
      useEffect(() => {
      const sessionId_patient = localStorage.getItem('pat_id'); 
        if (sessionId_patient) {
          setId_patient(sessionId_patient); // Set stored ID from session
        }
      }, []);


  // Handle message submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // console.log('msg',inputValue);
    // console.log('doctorid',storedId);
    // console.log('patientid',id_patient);

    // Check if the input is empty or contains only spaces
    if (inputValue.trim()) {
        const payload = {
            inputValue:inputValue,
            storedId:storedId,
            id_patient:id_patient
        };

      // Post the message to the server API
      //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/doctors/doctor_chat.php
      //../api/doctors/doctor_chat.php
    try {
      const response = await axios.post('../api/doctors/doctor_chat.php',
        payload
      );

      if (response.data.status === true) {
        toast.success('Doctor message sent successfully!');
        handleSend();

      } else {
        toast.error('Failed to send message. Please try again.');
      }
    } catch (error) {
      // Log and display more detailed error information
      const errorMessage = error.response ? error.response.data : error.message;
      // console.error('Error occurred during message submission:', errorMessage);
      toast.error('Error occurred. Please check the console for details.');
    }
  };
}

  // Handle sending message to the parent component
  const handleSend = () => {
    if (inputValue.trim()) {
      addMessage(inputValue); // Send the message to the parent component
      setInputValue(''); // Clear input field after sending
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="message-input">
        <form onSubmit={handleSubmit} method="post">
        {/* id store thse db ma doctor id*/}
        <input 
            type='hidden'
            name="storedId"
            value={storedId} // Update to use storedId directly
            onChange={(e) => setStoredId(e.target.value)}
          />  

           {/* id store thse db ma doctor id*/}
        <input 
            type='hidden'
            name="id_patient"
            value={id_patient} // Update to use storedId directly
            onChange={(e) => setId_patient(e.target.value)}
          />  

        {/* id store thse db ma patient id*/}
   
          <input
            type="text"
            name="inputValue"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)} // Handle input changes
            placeholder="Type a message"
          />
         
          <button type="submit" className='btn btn-outline-primary'>Send</button>
 
        </form>
      </div>
    </>
  );
};

export default DoctorMsgInput;
