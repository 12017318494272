import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../../css/doctor_chat.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const MessageList = ({ message }) => {
  const [doctor, setDoctor] = useState([]); // doctor mate
  const [error, setError] = useState(null);

  const loadUser = async () => {
    try {
      // Retrieve the patient ID from localStorage
      const storedPatientId = localStorage.getItem('pat_id');

      if (storedPatientId) {
        // console.log('Retrieved patient ID from localStorage:', storedPatientId);
        
        //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/doctors/d_chat_select.php
        //./../api/doctors/d_chat_select.php
        const result = await axios.get('./../api/doctors/d_chat_select.php', {
          params: {pat_id:storedPatientId } // Pass the patient ID as a query parameter
        });
        
        // Check if result contains messages
        if (result.data && result.data.doctorMsg) {
          setDoctor(result.data.doctorMsg);
        } else {
          setError('No messages found.');
        }

      } else {
        setError('Patient ID not found in localStorage.');
      }
    } catch (error) {
      setError('Failed to load messages.');
    }
  };

  useEffect(() => {
    loadUser();
  }, []);


//*********************  Chat container show the doctor & patient message *********************************** */

  return (
    <>
    <div className="message-list">
   
    {/* Display Patient messages if available */}
    {doctor.length > 0 ? (
    doctor.map((res, index) => (
    <div 
      key={index} 
      className={`${res.sender === 'doctor' ? 'message_right' : 'message_left'}`}
    >
      {/* Display sender's name */}
      <h5>{res.sender === 'doctor' ? 'Dr.' : 'Patient'}</h5>

      {/* Display the message */}
      {res.message}
        </div>
      ))
    ) : (
      <p>No messages available</p>
    )}
   
{/* *********************Only Patient Message sent*************************   */}

    {message.length > 0 ? (
      message.map((msg, index) => (
        <div key={index} className="message">

          {/*doctor message sent */}
          {msg}
        </div>
      ))
    ) : (
      <div className="no-messages">No messages yet</div> // Fallback for no patient messages
    )}
  </div>
  </>
);
}

export default MessageList;
