import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/toggle.css';

import $ from 'jquery';
import 'bootstrap-toggle/css/bootstrap-toggle.min.css';
import 'bootstrap-toggle/js/bootstrap-toggle.min.js';
import Footer from './footer';
import SIGNIN from '../image/signin.jpg';
import SIGNUP from '../image/signup.jpg';


const PatientReg = () => {

  //redirect use in page navigate
  const navigate = useNavigate();
  //[isActive] state us in toggle change in page from login and patient reg
  const [isActive, setIsActive] = useState(false);
  //token generate
  // const [token, setToken] = useState('');


  const toggleForm = () => {
    setIsActive(!isActive);
  };

  //use toggle button
  const [isOn, setIsOn] = useState(false);

    //login doctor & patient
    const [inputs, setInputs] = useState({
    phone_number: '',
    password_hash: '',
    userType: "patient" // Default to Patient initially
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };


  // Toggle switch function to toggle between Patient and Doctor
  const click = () => {
    const newUserType = isOn ? 'patient' : 'doctor'; // Switch between Patient and Doctor
    setIsOn(!isOn); // Toggle the state
    setInputs((prevState) => ({
      ...prevState,
      userType: newUserType // Update the userType based on the toggle
    }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/login/doctor_patient_signin.php
    // ./api/login/doctor_patient_signin.php
    try {
      const response = await axios.post('./api/login/doctor_patient_signin.php', { ...inputs}
      );
  
      if (response.data.status === true) {
        
        // Handle Patient Login
        if (inputs.userType === 'patient') {
          
          // Check if exercise_ids is empty or null
          if (response.data.exercise_ids === null || response.data.exercise_ids === '') {
            navigate('../card_exe/empty_exe');
          } else {
            
            // Proceed with login and token generation for patient
            const randomToken = generateRandomToken(); // Generate token
            localStorage.setItem('authToken', randomToken); // Store token in localStorage

            sessionStorage.setItem('patientId', response.data.patient_id); 
            // console.log(sessionStorage)
  
            toast.success('Patient Sign-in successfully!');
            setTimeout(() => {
              navigate('../card_exe/exercise');  
            }, 6000);
          }
  
        } else if (inputs.userType === 'doctor') {
          // Handle Doctor Login
          const randomToken = generateRandomToken(); // Generate token
          localStorage.setItem('authToken', randomToken); // Store token in localStorage

          sessionStorage.setItem('doctorId', response.data.doctor_id); //store session id in api throw 
          // console.log('doctorId:', sessionStorage.getItem('doctorId'));
          
          sessionStorage.setItem('specialization',response.data.specialization)
          // console.log('dr_spcialist',sessionStorage);

          toast.success('Doctor Sign-in successfully!');
          setTimeout(() => {
            navigate('./patient_details');
          }, 6000);
        }
  
      } else {
        // If login failed, show an error message
        toast.error(response.data.message || 'Sign-in failed. Please try again!');
      }
  
    } catch (error) {
      console.error('Error submitting sign-up form:', error);
      if (error.response) {
        console.error('Server responded with:', error.response.data);
      } else if (error.request) {
        console.error('Request made but no response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      toast.error('An error occurred during sign-up. Please try again later.');
    }
  };
  
      //---------Token generate--------------

      // Function to generate a random token
      const generateRandomToken = () => {
        const randomToken = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
        // console.log('Token generated: ', randomToken);
        return randomToken; // Return the generated token
      };

  // *********************START a patient Reg Account ************************************************

      const [patient_inputs, setPatient_input] = useState({
        full_name: '',
        age:'',
        email: '',
        phone_number:'',
        password: ''
      });


      const p_handleChange = (e) => {
        const { name, value } = e.target;
        setPatient_input((prevInputs) => ({ ...prevInputs, [name]: value }));
      };

      const handleSubmit = async (e) => {
        e.preventDefault();

        //validation-----------------

        // Check if any field is empty
        for (const key in patient_inputs) {
          if (patient_inputs[key].trim() === '') {
            toast.error(`${key.replace('_', ' ')} can not be empty.`);
            return;
          }
        }

            // Validate the phone number
        if (!/^\d{10}$/.test(patient_inputs.phone_number)) {
          // toast.error('Phone number must be exactly 10 digits.');
          toast.error('Phone number is a not set.');
          return;
        }

         // Validate the password strength
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        if (!passwordRegex.test(patient_inputs.password)) {
          //  toast.error('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.');
          toast.error('Password is a not set (use a uppercase, lowercase, number, special character).');
          return;
        }
        //----------------------------

        //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/patient/patient_insert.php
        //./api/patient/patient_insert.php
        try {
          const response = await axios.post('./api/patient/patient_insert.php', patient_inputs);
         
          if(response.data.status === true){
            // console.log(response);
          toast.success('Account is a created successfully !');
            //timeout function after 6 sconds redirect reg page 
            setTimeout(() => {
            //toggleForm() redirct in sign in
            toggleForm();
            },6000);
          }else{
            toast.error('Account is not created !');
          }

        } catch (error) {
          // console.error('Error submitting form:', error);
          toast.error('Failed to create patient account. Please try again.');
        }
    }


  return (
    <>
      <ToastContainer />
      
      <div>
        <section>
          <div className={`container ${isActive ? 'active' : ''}`}>
            <div className="user signinBx">
              <div className="imgBx">
                <img
                  src={SIGNIN}
                  style={{ width: '90%', height: '60%', marginTop: '20%', marginLeft: '5%', border: '3px solid black' }}
                  alt="Sign In"
                />
              </div>    

              <div className="formBx">
                <form onSubmit={handleLogin} method='POST'>

                
                {/* **********toggle********** */}

                 {/* Toggle Switch between Patient and Doctor */}
                 <div id="main-toggle" className="position-absolute top-0 end-0 p-3">
                  <span id="pa">Patient &nbsp;</span>
                  <label className="switch">
                    <input type="checkbox" checked={isOn} onClick={click} />
                    <span className="slider"></span>
                  </label>
                  <span id="dr">&nbsp; Doctor</span>
                </div>


              {/* ********************* */}

                  <div className='d-flex justify-content-center align-items-center'>
                    <img src='../MEDEUON.png' style={{ width:'50%', margin:'-60px 0px 50px 0px'}} />
                  </div>

                  <h2>Sign In</h2>

                  <input
                    type="text"
                    name="phone_number"
                    value={inputs.phone_number}
                    onChange={handleChange}
                    placeholder="Phone Number"
                  />
                  <input
                    type="text"
                    name="password_hash"
                    value={inputs.password_hash}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <input type="submit" value="Sign in" />

                  <p className="signup">
                    Don't have an account?{' '}
                    <a href="#" onClick={toggleForm}>
                      Sign Up.
                    </a>
                  </p>

                {/* over Patient Account */}

                  <p className="signup">
                    Doctor?{' '}
                    <Link to="/doctor_reg">Doctor sign up.</Link>
                  </p>

                  <div className='d-flex justify-content-center align-items-center'>
                    <span style={{margin:'50px 0 0 0px', fontWeight:'600'}}>Brand Advertis</span>
                    </div>
                 
                   {/* over Link */}
                </form>
                
              </div>
            </div>

         


{/* ************************************* Start Patient Reg Account ************************************* */}
            <div className="user signupBx">
              <div className="formBx">
                <form onSubmit={handleSubmit} method='POST'>
                  <h2>Create an Patient account</h2>
                  <input type="text" placeholder="Full Name" name="full_name" onChange={p_handleChange} />
                  <input type="number" placeholder="Age" name="age" onChange={p_handleChange} />
                  <input type="email" placeholder="Email Address" name="email" onChange={p_handleChange} />
                  <input type="number" placeholder="Phone Number" name="phone_number" onChange={p_handleChange} />
                  <input type="password" placeholder="Password" name="password" onChange={p_handleChange} />
                  <input type="submit" value="Sign Up" />
                  <p className="signup">
                    Already have an account?{' '}
                    <a href="#" onClick={toggleForm}>
                      Sign in.
                    </a>
                  </p>
                </form>
              </div>
              <div className="imgBx">
                <img
                  className="container text-center"
                  src={SIGNUP}
                  style={{ width: '90%', height: '60%', marginTop: '20%', marginLeft: '5%', border: '3px solid black' }}
                  alt="Sign Up"
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ************ footer ******************** */}
      <div>   
         <Footer/>
      </div>
      {/*  **************************************** */}

    </>
  );
};

export default PatientReg;
