import React from 'react';

// jsx file
import PatientReg from './components/patient_reg';
import DoctorReg from './components/doctor_reg';
import PatientDetails from './components/patient_details';
// import ExtraLogin from './components/extra_login';

import CardExe from './components/cards/card_exe';
import Exercise from './components/cards/exercise';
import EmptyExe from './components/cards/empty_exe';
import PatientChat from './components/cards/patient_chat';
import DoctorChat from './components/cards/doctor_chat';


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (

<Router>
      <div>
        <Routes>
          {/* read first page */}
          <Route path="/" element={<PatientReg />} />
          <Route path="Doctor_Reg" element={<DoctorReg />} />
          <Route path="Patient_Details" element={<PatientDetails />} />
          {/* <Route path="Extra_Login" element={<ExtraLogin />} /> */}

          <Route path="patient_chat" element={<PatientChat/>}/>
          <Route path="doctor_chat" element={<DoctorChat/>}/>
          <Route path="card_exe" element={<CardExe />} />
          <Route path="/card_exe/exercise" element={<Exercise />} />
          <Route path="/card_exe/empty_exe" element={<EmptyExe />} />

        </Routes>
      </div>
    </Router>
  
  );
}

export default App;
