import React from 'react';
import '../css/footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="warning-text">
          Statuary warning: This tool is used only by Registered Medical Practitioners. This is a helping tool. Though utmost care has been taken while creating this tool, MEDEUON will not be responsible for any errors or omissions of any kind in the process, content, and outcome.
        </p>
        <p className="copyright-text">
          © 2024 - 2025 MEDEUON. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
