import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DoctorMsgList from './DoctorMsgList';  // Updated component name for clarity
import DoctorMsgInput from './DoctorMsgInput'; // Updated component name for clarity
import "../../css/doctor_chat.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import Footer from '../footer';


const ChatApp = () => {
  const [messages, setMessages] = useState([]); // Renamed for better clarity
  const navigate = useNavigate();

//***************Token********************* */
     //check the token not tok then redirect the login(login time set token)
     useEffect(() => {
      // Get the token from localStorage 
      if (!localStorage.getItem('authToken')) {
        // If no token, redirect to Page 1 or login page
        navigate('../');
      }
    }, []);
 //-------------------------------   

  // Function to add a new message
  const addMessage = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]); // Adding new messages to the array
  };

  //**************back button***************** */
  const back = () => {
    navigate('../patient_details')
  };
    
  
  return (
    <>
      <div className="chat-app">
      <div className="header d-flex align-items-center justify-content-center">

        <div className="back" onClick={back}>
        <IoMdArrowRoundBack />
        </div>

          <h3 className="chat-title" style={{ color: 'black' }}>
            Chat
          </h3>
      </div>
        

        {/* Render the list of messages */}
        <DoctorMsgList message={messages} />  
        
        {/* Render the input for adding new messages */}
        <DoctorMsgInput addMessage={addMessage} />
      </div>

      <br/>
        
        {/* ************ footer ******************** */}
          <div>   
           <Footer/>
        </div>
      {/*  **************************************** */}

    </>
  );
};

export default ChatApp;
