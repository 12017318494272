
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import $ from 'jquery';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net'; // DataTables JS
import 'datatables.net-responsive'; // DataTables responsive plugin
import Footer from './footer';
// import Unread_Msg_count  from './unread_msg_count';
import { IoChatbubbles } from "react-icons/io5";
import { FaRegCircle } from "react-icons/fa6";

import { SiAndroidstudio } from "react-icons/si"; //header top only show mate
import { SiApachesolr } from "react-icons/si"; //react icon website : https://react-icons.github.io/react-icons/icons/si/
import { SiBaidu } from "react-icons/si";
import { SiCodeblocks } from "react-icons/si";
import { SiPicardsurgeles } from "react-icons/si";

import '../css/patient_details.css';
import '../css/unread_msg_count.css';

export default function PatientTable() {  
  const navigate = useNavigate();
    
 //**************** not login in redirect in login page ******************** */
  useEffect(() => {
    if (!localStorage.getItem('authToken')) {
      navigate('../');
    }
  }, [navigate]);

//************************* */

  const [user, setUser] = useState([]);
  const [count, setCount] = useState([]);

  const loadUser = async () => {
    //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/patient/patient_details.php
    // ./api/patient/patient_details.php
    try {
      const result = await axios.get('./api/patient/patient_details.php');
      setUser(result.data.phpresult);
    } catch (error) {
      // Handle error here
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (user.length > 0 && !$.fn.DataTable.isDataTable('#example')) {
      $('#example').DataTable({
        paging: true,
        searching: true,
        scrollY: '400px',
        scrollX: true,
        scrollCollapse: true,
        responsive: true,
      });
    }

    return () => {
      if ($.fn.DataTable.isDataTable('#example')) {
        $('#example').DataTable().destroy();
      }
    };
  }, [user]);


    // patient id (patient_reg)store thay che
    const patientIdFunction = (e) => {
      const p_id = e.target.value;
      sessionStorage.setItem('patientId',p_id);
      // console.log(p_id);
      navigate('../card_exe');
      console.log()
    };

  
        //click chat and store patient id  chat mate store thase
        const DoctorChat = (e) => {
          const pat_id = e.currentTarget.getAttribute('data-patient-id');
          if (pat_id) {
            localStorage.setItem('pat_id', pat_id); // Store patient ID
            console.log(pat_id);
            // Uncomment this when you're ready to navigate
            navigate('../doctor_chat');

          } else {
            console.error('Patient ID is undefined or not provided.');
          }
        };

        

  //****** Logout ***** */

  const HandleLogout = () =>{  
   
    Swal.fire({
      title: "Are you sure you want to logout?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Logged out!",
          text: "You have been successfully logged out.",
          icon: "success"
        }).then(() => {
          navigate('../');  // Redirect only after the confirmation
        });
      }
    });
  
    // Remove the localstorage token (authToken name same hovu joye)
    localStorage.removeItem('authToken');
  
  };      
        
//******************************* End ********************************************* */

  return (
    <>
    {/* header */}
     <div className='header-line'>
        <marquee>
        <SiAndroidstudio size={50} id="header-icon"/>
        <SiApachesolr size={50} id="header-icon"/>
        <SiBaidu size={50} id="header-icon"/>
        <SiCodeblocks size={50} id="header-icon"/>
        <SiPicardsurgeles size={50} id="header-icon"/>
        </marquee>
      </div>
    {/* ----------------- */}

    <div className="container mt-3">

        {/* ********* logout ********* */}

        <div className="container-fluid">
      <div className="row">
        <div className="col d-flex justify-content-end mt-2 mb-2">
          <button type="button" onClick={HandleLogout} className="btn btn-danger">Logout</button>
        </div>
      </div>
    </div>

      <div className="table-responsive">
        <h2 className='d-flex align-items-center justify-content-center'>Patient Details</h2>
        <div className="container-table">
          <table id="example" className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Full name</th>
                <th>Age</th>
                <th>Phone number</th>
                <th>Email</th>
                <th>Action</th>
                <th>Chat</th>
               
              </tr>
            </thead>
            <tbody>
              {user.map((res, index) => (
                <tr key={index}>
                  <td>{res.full_name}</td>
                  <td>{res.age}</td>
                  <td>{res.phone_number}</td>
                  <td>{res.email}</td>
                  <td>
                    <button value={res.patient_id} onClick={patientIdFunction} id="btn_1" className="btn btn-outline-primary">
                    Assign Exercise
                    </button>
                  </td>
                 
                  <td>
                  <button data-patient-id={res.patient_id} onClick={DoctorChat} id="btn_1" className="btn btn-outline-custom">
                    <IoChatbubbles size="30" id="chat_icon" />
              
                    {/* message count */}
                    <label className='main_count'>
                      {res.message_count}
                    </label>

                   {/* <Unread_Msg_count /> */}
                 
                  </button>
                  </td>
                </tr>
              ))}
              
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <br/>

          {/* ************ footer ******************** */}
        <div>   
         <Footer/>
       </div>
      {/*  **************************************** */}

    </>
  );
};
