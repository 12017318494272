import React from 'react';
import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import DIcon from '../../image/doctor.png';

import Footer from '../footer';



const EmptyExe = () => {

  const navigate = useNavigate();

    //***************Token********************* */
     //check the token not tok then redirect the login(login time set token)
     useEffect(() => {
      // Get the token from localStorage 
      if (!localStorage.getItem('authToken')) {
        // If no token, redirect to Page 1 or login page
        navigate('../');
      }
    }, []);

  return (
  <>

<div className="container" >
      <div className="row justify-content-center" style={{Bordercolor:'red'}}>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card text-center mt-3">
            <img src={DIcon} className="card-img-top" alt="Card Image" />
            <div className="card-body">
              <h5 className="card-title"><u>Suggestion</u></h5>
              <p className="card-text">
                <h6>(The doctor has not given you
                  the exercise yet)</h6>
                  
                Plesase contect for a doctor
              </p>
              <a href="../" className="btn btn-outline-primary">BACK</a>
            </div>
          </div>
        </div>
      </div>
    </div>   

        {/* ************ footer ******************** */}
        <div>   
           <Footer/>
        </div>
      {/*  **************************************** */}

  </>
  )
}

export default EmptyExe;

