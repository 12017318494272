import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/card_exe.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../footer';

export default function ExerciseCard() {
  
  //navigate page
  const navigate = useNavigate();
  
  // State to track which radio button is selected
  const [selectedExercise, setSelectedExercise] = useState('');
  
  // State to track selected exercise IDs
  const [selectedIds, setSelectedIds] = useState([]);

    //***************Token********************* */
     //check the token not tok then redirect the login(login time set token)
     useEffect(() => {
      // Get the token from localStorage 
      if (!localStorage.getItem('authToken')) {
        // If no token, redirect to Page 1 or login page
        navigate('../');
      }
    }, []);

  // ------------------card click and change------------------------------
  const [isChecked, setIsChecked] = useState({
    JumpingJacks: false,
    KickBoxing: false,
    RunningStairs: false,
    JumpRope: false,
    Squat: false,
    Fitball: false,
    Treadmill: false,
    lumbar: false,
    eyes: false
  });

  // Handle radio button change
  const handleRadioChange = (e) => {
    setSelectedExercise(e.target.value);
  };

  // Handle card checkbox selection
  const handleCardChange = (exercise, id) => {
    setIsChecked((prevState) => ({
      ...prevState,
      [exercise]: !prevState[exercise], // Toggle the checkbox state
    }));
    
    setSelectedIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((item) => item !== id); // Remove ID if already selected
      } else {
        return [...prevIds, id]; // Add ID if not selected
      }
    });
  };

  //--------------session Table mathee avse Patient Id --------------------------------

  const [storedId, setStoredId] = useState(null);

  useEffect(() => {
    const storedId = sessionStorage.getItem('patientId'); 
    setStoredId(storedId);
    // console.log("second page : ", storedId);
  }, []); 

  //--------------login mathee DR_specialist avse session thee--------------------------------

    const [doctor_specialist, setDoctor_specialist] = useState(null);

    useEffect(() => {
      const doctor_specialist = sessionStorage.getItem('specialization'); 
      setDoctor_specialist(doctor_specialist);
      // console.log("doctor_specialist : ", doctor_specialist);
    }, []); 

  // -----------update Handle form submission-----------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    //store id is session id
      if (storedId) {
          // console.log("second page: Retrieved patient ID:", storedId);

          if (selectedIds.length > 0) {

            //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/patient/update_exe_id.php
            //./api/patient/update_exe_id.php
          try {
            const response = await axios.post('./api/patient/update_exe_id.php',
              { patient_id: storedId, exercise_ids: selectedIds } // Send the selected IDs to the backend
            );
            
            if (response.data.status === true) {
            
              Swal.fire({
                title: "Exercise Assign Successfully !",
                text: "",
                icon: "success"
              }).then(() => {

                navigate('./exercise');
              });
            } else {
              toast.warn('Failed to update exercises!');
            }
        
          } catch (error) {
            toast.error('Submission error.');
          }
        }
         else {
          toast.error('Please select at least one exercise.');
      
        }
      }
}


  return (
<>
    <ToastContainer/>

    <div className='card_exe'>
    <div className="d-flex justify-content-center align-items-center mt-20 mb-10" style={{ minHeight: '70vh' }}>
      <div className="card" style={{ maxWidth: '60%', width: '90%' }}>
        <div className="card-body">
          <h5 className="card-title d-flex justify-content-center text-center mb-4" id='title'>Exercises</h5>
          <h6 className="card-subtitle mb-2 text-muted text-center" id="subtitle">|| You select the exercise for your patient || </h6>
          
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            {/* Radio Button for Cardiology */}
            <div className="mt-4">
              {/* this coditon is a check table specialist and this page specialist is a same and show h5 tag and radio button 
              otherwise hide */}
            {doctor_specialist === 'cardiology' && (
              <h5>
                Cardiology :
                <input 
                  type="radio" 
                  id="exe_id"
                  name="exe" 
                  value="cardiology" 
                  onChange={handleRadioChange} 
                />
              </h5>
              )}
               
              {selectedExercise === 'cardiology' && (
                <div className="row">
                  <div className="col-sm-3">
                    <div className={`card ${isChecked.JumpingJacks ? 'checked' : ''}`} onClick={() => handleCardChange('JumpingJacks', 1)}>
                      <div className="card-body">
                        <h5 className="card-title">Jumping jacks</h5>
                        <p className="card-text">...</p>
                        <input type="checkbox" id="c1" checked={isChecked.JumpingJacks} readOnly/>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className={`card ${isChecked.KickBoxing ? 'checked' : ''}`} onClick={() => handleCardChange('KickBoxing', 2)}>
                      <div className="card-body">
                        <h5 className="card-title">Kickboxing</h5>
                        <p className="card-text">..</p>
                        <input type="checkbox" id="c1" checked={isChecked.KickBoxing} readOnly />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className={`card ${isChecked.RunningStairs ? 'checked' : ''}`} onClick={() => handleCardChange('RunningStairs', 3)}>
                      <div className="card-body">
                        <h5 className="card-title">Running the stairs</h5>
                        <p className="card-text">..</p>
                        <input type="checkbox" id="c1" checked={isChecked.RunningStairs} readOnly />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className={`card ${isChecked.JumpRope ? 'checked' : ''}`} onClick={() => handleCardChange('JumpRope', 4)}>
                      <div className="card-body">
                        <h5 className="card-title">Jump Rope</h5>
                        <p className="card-text">..</p>
                        <input type="checkbox" id="c1" checked={isChecked.JumpRope} readOnly />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            

            {/* Radio Button for Orthopedic */}
            <div className="mt-4">
            {doctor_specialist === 'orthopedic' && (
              <h5>
                Orthopedic :
                <input 
                  type="radio" 
                  id="exe_id"
                  name="exe" 
                  value="orthopedic" 
                  onChange={handleRadioChange} 
                />
              </h5>
            )}

              {selectedExercise === 'orthopedic' && (
                <div className="row">
                  <div className="col-sm-3">
                    <div className={`card ${isChecked.Squat ? 'checked' : ''}`} onClick={() => handleCardChange('Squat', 5)}>
                      <div className="card-body">
                        <h5 className="card-title">Squat</h5>
                        <p className="card-text">..</p>
                        <input type="checkbox" id="c1" checked={isChecked.Squat} readOnly />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className={`card ${isChecked.Fitball ? 'checked' : ''}`} onClick={() => handleCardChange('Fitball', 6)}>
                      <div className="card-body">
                        <h5 className="card-title">Fitball</h5>
                        <p className="card-text">..</p>
                        <input type="checkbox" id="c1" checked={isChecked.Fitball} readOnly />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className={`card ${isChecked.Treadmill ? 'checked' : ''}`} onClick={() => handleCardChange('Treadmill', 7)}>
                      <div className="card-body">
                        <h5 className="card-title">Treadmill</h5>
                        <p className="card-text">..</p>
                        <input type="checkbox" id="c1" checked={isChecked.Treadmill} readOnly />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>


            {/* Radio Button for Neurologist */}
            <div className="mt-4">
            {doctor_specialist === 'neurologist' && (
              <h5>
              Neurologist :
                <input 
                  type="radio" 
                  id="exe_id"
                  name="exe" 
                  value="neurologist" 
                  onChange={handleRadioChange} 
                />
              </h5>
            )}

              {selectedExercise === 'neurologist' && (
                <div className="row">
                  <div className="col-sm-3">
                    <div className={`card ${isChecked.lumbar ? 'checked' : ''}`} onClick={() => handleCardChange('lumbar', 8)}>
                      <div className="card-body">
                        <h5 className="card-title">lumbar Exercise</h5>
                        <p className="card-text">..</p>
                        <input type="checkbox" id="c1" checked={isChecked.lumbar} readOnly />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className={`card ${isChecked.eyes ? 'checked' : ''}`} onClick={() => handleCardChange('eyes', 9)}>
                      <div className="card-body">
                        <h5 className="card-title">Eyese Rolling</h5>
                        <p className="card-text">..</p>
                        <input type="checkbox" id="c1" checked={isChecked.eyes} readOnly />
                      </div>
                    </div>
                  </div>
              
                </div>
              )}
            </div>

            
              <div className="d-flex justify-content-center mt-4">
                <button type="submit" className="btn btn-outline-primary btn-lg">Assign</button>
            </div>

          </form>
        </div>
      </div>
    </div>
    </div>
    
        {/* ************ footer ******************** */}
        <div>   
           <Footer/>
        </div>
      {/*  **************************************** */}



</>

  );
}
