import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../../css/patient_chat.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const MessageInput = ({ addMessage }) => {
  const [storedId, setStoredId] = useState(''); // Store patient ID
  const [inputValue, setInputValue] = useState(''); // Store message input 

  // Retrieve patient ID from session storage
  useEffect(() => {
    const sessionId = sessionStorage.getItem('patientId'); 
    if (sessionId) {
      setStoredId(sessionId); // Set stored ID from session
      // console.log('Stored patientId:', sessionId);
    }
  }, []);



  // Handle message submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    if (inputValue.trim()) {
      const payload = {
        inputValue: inputValue,
        storedId: storedId
      };

      try {
        //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/patient/patient_chat.php
        //../api/patient/patient_chat.php
        const response_msg = await axios.post('../api/patient/patient_chat.php', payload);
       
        if (response_msg.data.status === true) {
          toast.success('Message is a sent !'); 
          handleSend(); // Call handleSend to add the message
        } else {
          toast.error('Not sent!');
        }
  
      } catch (error) {
        toast.error('Failed. Please try again.');
      }
    }
  };

  // Handle sending message to the parent component
  const handleSend = () => {
    if (inputValue.trim()) {
      addMessage(inputValue); // Send the message to the parent component
      setInputValue(''); // Clear input field after sending
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="message-input">
        <form onSubmit={handleSubmit}>
          <input 
            type='hidden'
            name="storedId"
            value={storedId} // Update to use storedId directly
            onChange={(e) => setStoredId(e.target.value)}
            placeholder="Enter Patient ID" // Added placeholder for clarity
          />      

          <input
            type="text"
            name="inputValue"
            value={inputValue} // Update to use inputValue directly
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type a message"
          />
          <button type="submit" className='btn btn-outline-primary btn-lg'>Send</button> {/* Removed onClick here */}
        </form>
      </div>
    </>
  );
};

export default MessageInput;
